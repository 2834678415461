import { render, staticRenderFns } from "./currentOrder.vue?vue&type=template&id=2a9f8769&scoped=true&"
import script from "./currentOrder.vue?vue&type=script&lang=js&"
export * from "./currentOrder.vue?vue&type=script&lang=js&"
import style0 from "./currentOrder.vue?vue&type=style&index=0&id=2a9f8769&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9f8769",
  null
  
)

export default component.exports